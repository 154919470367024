<template>
	<div class="padding-top-10 ">
		<top :bgWhite='true'></top>
		<div class="content  padding-bottom-60 ">
			<div class="flex align-center justify-between w60">
				<div class="flex align-center writer-info">
					<img src="../../assets/image/home/head.png" class="block-48 margin-right-xs">
					<div class="left-info">
						<p class="name">三毛</p>
						<div class="writer-tag padding-top-10 flex align-center">
							<el-tag type="warning" size="mini">作家标签</el-tag>
						</div>
					</div>
				</div>
				<div class="text-999">
					<span class="time margin-right-xs fs12">15分钟前</span>
					<el-dropdown trigger="click">
						<span class="el-dropdown-link">
							<i class="el-icon-more text-999"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item class="clearfix text-999">
								<img src="../../assets/image/home/collect-gray.png" class="block-12">
								关注
							</el-dropdown-item>
							<el-dropdown-item class="clearfix text-999">
								<img src="../../assets/image/home/report.png" class="block-12">
								举报
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="w60 padding-top-10 fs14 text-666">
				<p class="bold text-333 margin-bottom-10">第二届线上题材正文</p>
				<span v-html="content"></span>
				<p class="text-2em">请问《上下五千年》
					原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写一套包括整个古代史时期的故事。由于他过早地离开了人世，他只写到东汉以前的部分，没能实现他的写作计划。少年儿童出版社的编辑同志把他的《上下五千年》原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写一套包括整个古代史时期的故事。由于他过早地离开了人世，他只写到东汉以前的部分没能实现他的写作计划。少年)
					L童出版社的编辑同志把他的遗稿交给我，要我整理和补写一-些篇目，并且把林先生未写的部分(从三国到清代鸦片战争前)续写完成。
					林汉达教授是一位教育
					家和语言学家。他在写历史故事方面，是下过一-番功夫的。他把写历史故事作为对“新语文的尝试和旧故事的整理”。他所写的不少历史故事读物，有其独特的风格，早已脍炙人口。要续写他的稿子，是相当困难的。但是经过再三考虑，我还是接受了这个任务。
					我接受这个任务的主要原因是我自己也一直有这样的愿望， 想给少年读者写-本介绍中国历史的读物。我认为做一个中国人，应该了解一点中国的历史。我们的祖国是一一个伟大的国家。
					中华民族是一个有悠久、
					灿烂文化的民族。了解祖国的过去，才能更加热爱祖国的现在和将来。在我国漫长的历史过程中，发生过许多有意义的事件，涌现出许多优秀的人物，把这些历史事件和人物介绍给少年读者，可以开阔他们的眼界，启发他们的智慧。
					请问《上下五千年》
					原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写-套包括整个古代史时期事。由于他过早地离开了人世，他只写到东汉以前的部分，没能实现他的写作计划。少年儿童出版社的编辑同志把他的《上下五千年》原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写一套包括整个古代史时期的故事。
					由于他过早地离开了人世，他只写到东汉以前的部分，没能实现他的写作计划。少年)
					L童出版社的编辑同志把他的遗稿交给我，要我整理和补写一些篇目，并且把林先生未写的部分(从三国到清代鸦片战争前)续写完成。
					林汉达教授是一位教育 家和语言学家。他在写历史故事方面，是下过一番
					功夫的。他把写历史故事作为对“新语文的尝试和旧故事的整理”。他所写的不少历史故事读物，有其独特的风格，早已脍炙人口。要续写他的稿子，是相当困难的。但是经过再三考虑，我还是接受了这个任务。
					我接受这个任务的主要原因是我自己也一直有这样的愿望， 想给少年读者写-本介绍中国历史的读物。我认个中国人，应该了解-点中国的历史。我们的祖国是一 个伟大的国家。中华民族是一
					个有悠久、灿烂文化的民族。了解祖国的过去，才能更加热爱祖国的现在和将来。在我国漫长的历史过程中，发生过许多有意义的事件，涌现出许多优秀的人物，把这些历史事件和人物介绍给少年读者，可以开阔他们的眼界，启发他们的智….
					青问《上下五千年》原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写一套包括整个古代史时期的事。由于他过早地离开了人世，他只写到东汉以前的部分，没能实现他的写作计划。少年)
					L童出版社的编辑同志把他的《上下五千年》原有已故林汉达教授的一份未完成的遗稿。林先生生前曾打算写一套包括整个古代史时期的故事。
					由于他过早地离开了人世，他只写到东汉以前的部分，没能实现他的写作计划。少年儿童出版社的编辑同志把他的遗稿交给我，要我整理和补写一些篇目，并且把林先生未写的部分(从三国到清代鸦片战争前)续写完成。
					林汉达教授是- -位教育家和语言学家。他在写历史故事方面，是下过一番功夫的。 他把写历史故事作为对“新语文的尝 试和旧故事的整
					理”。他所写的不少历史故事读物，有其独特的风格，早已脍炙人口。要续写他的稿子，是相当困难的。但是经过再三考虑，我还是接
					受了这个任务。
					我接受这个任务的主要原因是我自己也一直 有这样的愿望，想给少年读者写一-本介绍中国历史的读物。 我认为做一一个中国人， 应该了解
					一点中国的历史。我们的祖国是一个伟大的国家。中华民族是一个有悠久、 灿烂文化的民族。了解祖国的过去，才能更加热爱祖国的现
					在和将来。在我国漫长的历史过程中，发生过许多有意义的事件，涌现出许多优秀的人物，把这些历史事件和人物介绍给少年读者，可
					以开阔他们的眼界，启发他们的智..</p>
			</div>
			<div class="w60">
				<div class="solid-bottom padding-tb-20 flex align-center justify-around">
					<div class="flex align-center text-999">
						<i class="el-icon-star-off "></i>
						<span class="padding-left-5">收藏</span>
					</div>
					<div class="flex align-center text-999">
						<img src="../../assets/image/home/share.png" class="block-16">
						<span class="padding-left-5">66</span>
					</div>
					<div class="flex align-center text-999">
						<img src="../../assets/image/home/message.png" class="block-16">
						<span class="padding-left-5">96</span>
					</div>
					<div class="flex align-center text-999">
						<img src="../../assets/image/home/like.png" class="block-16">
						<span class="padding-left-5">58</span>
					</div>
				</div>
				<div class=" padding-tb-20 ">
					<textarea rows="6" cols="" class="bg-f6f7 w100"></textarea>
					<div class="flex align-center justify-end padding-top-15">
						<img src="../../assets/image/icon/smile.png" class="block-28">
						<span class="text-white bg-e54f  padding-lr-15 padding-tb-5 radius-6 margin-left-15">评论</span>
					</div>
				</div>
				<div class="padding-top-15">
					<div v-for="(item,index) in lists" :key="index" class="margin-bottom-22">
						<evaluate-item :info='item' :show_star='false'></evaluate-item>
					</div>
				</div>
			</div>
			<div class="flex align-center justify-center">
				<el-pagination background layout="prev, pager, next" :total="1000" :page-size='20'
					:hide-on-single-page='true' @prev-click='prev-click' @next-click='next-click'>
				</el-pagination>
			</div>
		</div>
		<!-- 悬浮按钮 -->
		<div class="float-box flex flex-direction align-center justify-around" v-show="scrollTop>500">
			<div class=" flex align-center justify-center  radius-100" style="margin-bottom: -20px;">
				<img src="../../assets/image/home/text.png" class="block-60" @click="dialogVisible = true">
			</div>
			<div class=" flex align-center justify-center  radius-100">
				<img src="../../assets/image/home/top1.png" class="block-100" @click="scrollToTop">
			</div>
		</div>
		<!-- 赞赏弹窗 -->
		<el-dialog title="赏叶子啊" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="">
				<el-row :gutter="24">
					<el-col :span="8" v-for="(ele,j) in awardLists" :key="j" class="margin-bottom-10 point">
						<div class=" tabItem flex  align-center justify-center" :class="awardTab==j?'selected':'normal'"
							@click="tabSelect(item,j)">
							<span class="text-666 point fs9">{{ele.name}}元</span>
						</div>
					</el-col>
				</el-row>
				<div class="flex align-center margin-top-20">
					<el-row :gutter="24" class="flex align-center">
						<el-col :span="14">
							<el-input placeholder="自定义(元)"></el-input>
						</el-col>
						<el-col :span="10">
							<el-input-number v-model="num" :step="2" size="mini"></el-input-number>
						</el-col>
					</el-row>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="danger" @click="admire">赏</el-button>
			</span>
		</el-dialog>
		<Modal :show="successModal" @hideModal="successModal = false" @submit="submit">
			<div class="modal-box flex flex-direction align-center justify-end padding-bottom-10">
				<div class="flex flex-direction align-center">
					<p class="fs16">打赏成功！</p>
					<div class="flex align-center">
						<p class="fs9">感谢您对创作者的支持！</p>
						<img src="../../assets/image/flower.png" class="block-16">
					</div>
				</div>
			</div>
		</Modal>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import Modal from '@/components/pop-up.vue';
	import evaluateItem from '@/components/evaluate/evalaute.vue';
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'artileDetail',
		components: {
			bottom,
			top,
			evaluateItem,
			Modal
		},
		data() {
			return {
				successModal: false,
				awardTab: 0, //索引
				awardLists: [{
						id: 1,
						name: '1'
					},
					{
						id: 1,
						name: '5'
					},
					{
						id: 1,
						name: '10'
					},
					{
						id: 1,
						name: '15'
					},
					{
						id: 1,
						name: '20'
					}
				],
				dialogVisible: false,
				content: '', //富文本内容
				editorOption: {},
				imageUrl: '', //封面
				scrollTop: 0,
				lists: [{}]
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			// 赞赏
			admire() {
				this.dialogVisible = false;
				this.successModal = true;
			},
			handleScroll(e) {
				this.scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
				console.log(this.scrollTop);
			},
			// 显示按钮的显示与隐藏
			scrollToTop() {
				let scrollToptimer = setInterval(function() {
					// console.log("定时循环回到顶部");
					var top = document.body.scrollTop || document.documentElement.scrollTop;
					var speed = top / 4;
					document.documentElement.scrollTop -= speed;
					if (top == 0) {
						clearInterval(scrollToptimer);
					}
				}, 30);
			},
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt10M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isLt10M;
			},
			submitForm() {
				this.$router.push({
					path: '/memberDate'
				})
				console.log(this.$refs.text.value)
			},
			// 选择赞赏金额
			tabSelect(item, index) {
				this.awardTab = index
			}
		}
	}
</script>

<style scoped lang="scss">
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content {
		width: 80%;
		height: auto;
		margin: 60px auto;
	}


	.float-box {
		position: fixed;
		right: 120px;
		bottom: 138px;
		width: 48px;
		height: 164px;
		opacity: 1;
		border-radius: 43px;

		.img-box {

			box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
		}
	}

	.tabItem {
		width: 80px;
		height: 32px;
		opacity: 1;
		border-radius: 5px;
	}

	.normal {
		background: #F6F7F9;
		border: 1px solid #F6F7F9;
		color: #666666;
	}

	.selected {
		background: rgba(229, 79, 66, 0.08);
		border: 1px solid #EA5046;
		color: #E54F42;
	}

	.dialog-footer {
		button {
			width: 100%;
			border-radius: 29px;
		}
	}
	.modal-box{
		width: 194px;
		height: 190px;
		background: url(../../assets/image/reward-bg.png) no-repeat;
		background-size: 100% 100%;
	}
</style>
