<template>
	<div class="modal-bg" v-show="show" >
		<div class="modal-container">
			<div class="modal-main">
				<slot></slot>
			</div>
			<div class="modal-footer">
				<i class="el-icon-circle-close text-white fs20" @click="hideModal"></i>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'modal',
		props: {
			show: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				x: 0,
				y: 0,
				node: null,
				isCanMove: false
			}
		},
		mounted() {
			this.node = document.querySelector('.modal-container')
		},
		methods: {
			hideModal() {
				this.$emit('hideModal')
			},

			submit() {
				this.$emit('submit')
			}
		}
	}
</script>

<style>
	.modal-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		z-index: 99999;
	}

	.modal-container {
		border-radius: 10px;
		overflow: hidden;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.modal-header {
		height: 56px;
		background: #409EFF;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: move;
	}

	.modal-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 57px;
	}

	.modal-footer button {
		width: 100px;
	}

	.modal-main {
		
	}
</style>
